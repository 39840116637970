<template>
  <div id="DoSeach">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <!-- 选项区 -->
      <div class="loading" v-show="loading">
        <div class="loading-content">
          <img src="https://newstore.vynior.com/close1%402x.png" alt="" class="loading-close" @click="loadingClose" />
          <el-progress :percentage="loadingProgress" :show-text="false" color="#fff" :stroke-width="4" class="loading-progress"></el-progress>
          <div class="loading-text">
            {{ loadingProgress >= 100 && analyzeStatus ? this.$t(`message.loading.Successsuanfa`) : this.$t(`message.loading.suanfa`) }}
          </div>
        </div>
      </div>
      <div class="H_Title">
        <div class="bar_One">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item :label="$t(`message.History.Category`)">
              <el-select v-model="category" :placeholder="$t(`message.History.Select`)" @change="classificationSelect">
                <el-option :label="item" :value="item" v-for="(item, index) in category_arrs" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div :class="showValue == 0 ? 'block' : 'blocks'">
          <span class="demonstration">{{ $t(`message.History.Products`) }}</span>
          <el-cascader
            v-model="contrast"
            :value="contrast"
            :placeholder="$t(`message.History.Select1`)"
            :options="options"
            :show-all-levels="false"
            :props="{ multiple: true, emitPath: true }"
            filterable
            ref="cascaderAddr"
            @change="productSelect"
            popper-class="location"
            :disabled="disabled">
            <template slot-scope="{ data }">
              {{ data.label }}
              <span
                v-if="contrast.length >= 30 && $store.state.radarRole == false"
                class="exceeded-overlay"
                @click="handleSelectionExceeded(0)"></span>
              <span v-if="contrast.length >= 8 && $store.state.radarRole == true" class="exceeded-overlay" @click="handleSelectionExceeded(1)"></span>
            </template>
          </el-cascader>
        </div>
        <div :class="showValue == 0 ? 'C_button' : 'C_button_s'" v-show="$store.state.lang == 'cn'">
          <img src="https://newstore.vynior.com/dbfx1.png" @click="resetChoose" />
          <img v-show="showValue == 1" src="https://newstore.vynior.com/Group%2033589%402x.png" class="c_action" alt="" @click="req_alg" />
          <img @click="historyDerive" src="https://newstore.vynior.com/emport_data.png" class="c_export" />
        </div>
        <div :class="showValue == 0 ? 'C_button' : 'C_button_s'" v-show="$store.state.lang == 'en'">
          <img src="https://newstore.vynior.com/resetsfs.png" @click="resetChoose" class="reset" />
          <img v-show="showValue == 1" src="https://newstore.vynior.com/startss.png" class="c_action" alt="" @click="req_alg" />
          <img @click="historyDerive" src="https://newstore.vynior.com/exportstts.png" class="c_export" />
        </div>
      </div>
      <div class="H_choose">
        <div class="choose_box">
          <div class="c_box1" id="gg" @click="changeComponent('panel')" :class="change_BGcolor == 0 ? 'change_BGcolor' : ''">
            <span>{{ $t(`message.History.Sensory`) }}</span>
          </div>
          <div class="c_box2" id="yc" @click="changeComponent('like')" :class="change_BGcolor == 1 ? 'change_BGcolor2' : ''">
            <span>{{ $t(`message.History.Predicted`) }}</span>
          </div>
        </div>

        <!-- 控制显示隐藏 -->
        <div class="H_display1" v-show="radarRole == true">
          <div class="text">{{ $t(`message.History.Scale`) }}</div>
          <el-switch v-model="$store.state.line_num" active-color="#1D5CA6" inactive-color="#E0E3E8"> </el-switch>
        </div>
        <!-- 控制显示隐藏 -->
        <div class="H_display2" v-show="showValue == 1 && radarRole == false">
          <div class="loding-box-show">
            <div class="loding-box-show-text">
              {{ $t(`message.Tooltip.Title`) }}<br />
              {{ $t(`message.Tooltip.P80`) }} <br />
              {{ $t(`message.Tooltip.P60`) }}<br />
              {{ $t(`message.Tooltip.P40`) }}<br />
              {{ $t(`message.Tooltip.P20`) }}<br /><br />
              {{ $t(`message.Tooltip.information`)
              }}<span style="color: #ff961e; border-bottom: 1px solid #ff961e" @click="changhelpDialogRole">{{ $t(`message.Tooltip.Help`) }}</span>
            </div>
          </div>
          <span class="button-loding">
            <img src="https://newstore.vynior.com/Subtract%402x.png" alt="" class="Pepole" />
            {{ $t(`message.History.Percentile`) }}</span
          >
          <!-- <el-switch v-model="showLine" @click="showLine = !showLine" active-color="#1D5CA6" inactive-color="#E0E3E8"> </el-switch> -->
        </div>
        <el-select v-show="radarRole == false && showValue == 1" v-model="likevalue" placeholder="请选择" @change="likeChange">
          <el-option v-for="item in likeoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <div class="H_button" @click="keep" v-show="$store.state.lang == 'cn'">
          <img src="https://newstore.vynior.com/ls2.png" alt="" v-show="radarRole == false" />
          <img src="https://newstore.vynior.com/Group%20553%402x.png" alt="" v-show="radarRole == true" />
        </div>
        <div class="H_button" @click="keep" v-show="$store.state.lang == 'en'">
          <img src="https://newstore.vynior.com/radarimg.png" alt="" v-show="radarRole == false" />
          <img src="https://newstore.vynior.com/histogram.png" alt="" v-show="radarRole == true" />
        </div>
      </div>

      <H :helpDialogRoles="helpDialogRole" :signName="signName" v-if="helpDialogRole" @unloding1="unloding1"></H>
      <!--  -->
    </div>
    <!-- ! 显示切换 -->
    <!-- 提示框 -->
    <div class="notarize_message_box" v-show="hint">
      <div class="notarize_message">
        <!-- 内容信息 -->
        <div class="content_text">
          <div class="line_1" v-show="$store.state.lang == 'cn'">
            您确定切换雷达图进行比较吗?
            <img src="https://newstore.vynior.com/xxx.png" alt="" @click="hint = false" />
          </div>
          <div class="line_1_en" v-show="$store.state.lang == 'en'">
            <div class="text">Are you sure you switch the radar chart ?</div>

            <img src="https://newstore.vynior.com/xxx.png" alt="" @click="hint = false" />
          </div>
          <div class="line_2" v-show="$store.state.lang == 'cn'">
            <img src="https://newstore.vynior.com/gth.png" alt="" />
            雷达图显示上线为<span style="color: #1d5ca6">8</span>个，超出的产品将不显示在图表中
          </div>
          <div class="line_2" v-show="$store.state.lang == 'en'">
            <img src="https://newstore.vynior.com/gth.png" alt="" />
            The radar chart limit is<span style="color: #1d5ca6"> 8</span> products
          </div>
          <div class="line_3">
            <span class="cancle" @click="hint = false">{{ $t(`message.Project.Cancel`) }}</span>
            <span class="sure" @click="true_keep">{{ $t(`message.Project.OK`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import H from '@/components/HelpCenters.vue';
import { HttpClient } from '@/utils/http';
import getFilename from '@/utils/getFilename';
export default {
  data() {
    return {
      indices: [],
      loadingTimer: null,
      loading: false,
      signName: 'ItemAnalysis',
      loadingProgress: 0,
      analyzeStatus: false,
      category: '',
      category_arrs: [], // HINT 原选择产品品类数组
      contrast: [], // HINT 原选择产品数组
      productIdList: [],
      options: [],
      disabled: true,
      showValue: 0, //判断 是否为预测属性模块
      change_BGcolor: 0,
      radarRole: false,
      nameList: [],
      showLine: false,
      helpDialogRole: false, //帮助中心的显示与隐藏
      currentHelp: 1, //帮助中心显示百分线
      likeoptions: [
        {
          //下拉框的数据
          value: '不显示',
          label: '不显示',
        },
        {
          //下拉框的数据
          value: 'ALL SPF',
          label: 'ALL SPF',
        },
        {
          value: 'SPF≥50',
          label: 'SPF≥50',
        },
        {
          value: 'SPF<50',
          label: 'SPF<50',
        },
        {
          value: '物理防晒',
          label: '物理防晒',
        },
        {
          value: '化学防晒',
          label: '化学防晒',
        },
        {
          value: '物化结合',
          label: '物化结合',
        },
      ],
      likeoptions_en: [
        {
          //下拉框的数据
          value: 'Not displayed',
          label: 'Not displayed',
        },
        {
          //下拉框的数据
          value: 'ALL SPF',
          label: 'ALL SPF',
        },
        {
          value: 'SPF≥50',
          label: 'SPF≥50',
          disabled: true,
        },
        {
          value: 'SPF<50',
          label: 'SPF<50',
        },
        {
          value: 'Chemical',
          label: 'Chemical',
        },
        {
          value: 'Physical',
          label: 'Physical',
        },
        {
          value: 'Hybird',
          label: 'Hybird',
        },
      ],
      likevalue: '', //多选框的值
      hint: false,
    };
  },
  watch: {
    // !contrast
    contrast: {
      handler() {
        console.log('选择数据改变');
        if (this.$store.state.radarRole == false) {
          console.log('柱状图');
        } else {
          console.log('改变了');

          let arr = this.$store.state.productNameList.slice(0, 8);
          this.$store.commit('$_changeProductNameList', arr);
          console.log('改变了111');
          if (this.contrast.length > 8) {
            console.log('1111111111111');
            this.handleSelectionExceeded();

            // !超出逻辑
            this.$nextTick(() => {
              this.contrast = this.contrast.slice(0, 8);
              this.productIdList = this.productIdList.slice(0, 8);
              this.$store.state.commit(' $_changeProductNameList', this.productIdList);
              // !拿数据进行截取

              // this.$refs.cascaderAddr.checkedValue = this.values;
              // this.$ref.cascaderAddr.computePresentContent();
            });
            return;
          }
        }
      },
    },

    '$store.state.lang': {
      handler() {
        if (this.$store.state.lang == 'en') {
          if (this.category_arrs[0] == '防晒霜') {
            this.category_arrs = ['Sun care'];
          }
          if (this.category == '防晒霜') {
            this.category = 'Sun care';
          }
          //!初始赋值
          this.likeoptions = this.likeoptions_en;
          // !切换判断赋值
          switch (this.likevalue) {
            case '物理防晒':
              this.likevalue = 'Chemical';
              break;
            case '化学防晒':
              this.likevalue = 'Physical';
              break;
            case '物化结合':
              this.likevalue = 'Hybird';
              break;
            case '不显示':
              this.likevalue = 'Not displayed';
              break;
          }
        }
        if (this.$store.state.lang == 'cn') {
          if (this.category_arrs[0] == 'Sun care') {
            this.category_arrs = ['防晒霜'];
          }
          if (this.category == 'Sun care') {
            this.category = '防晒霜';
          }
          this.likeoptions = [
            {
              //下拉框的数据
              value: '不显示',
              label: '不显示',
            },
            {
              //下拉框的数据
              value: 'ALL SPF',
              label: 'ALL SPF',
            },
            {
              value: 'SPF≥50',
              label: 'SPF≥50',
              disabled: true,
            },
            {
              value: 'SPF<50',
              label: 'SPF<50',
            },
            {
              value: '物理防晒',
              label: '物理防晒',
            },
            {
              value: '化学防晒',
              label: '化学防晒',
            },
            {
              value: '物化结合',
              label: '物化结合',
            },
          ];
          switch (this.likevalue) {
            case 'Chemical':
              this.likevalue = '物理防晒';
              break;
            case 'Physical':
              this.likevalue = '化学防晒';
              break;
            case 'Hybird':
              this.likevalue = '物化结合';
              break;
            case 'Not displayed':
              this.likevalue = '不显示';
              break;
          }
        }
      },
    },
    showLine(newValue) {
      if (newValue != this.$store.state.showLine) this.$store.commit('$_changeShowLine', newValue);
    },
    '$store.state.showLine': {
      handler(newValue) {
        this.showLine = newValue;
      },
    },
    radarRole() {
      this.$store.commit('$_changradarRole', this.radarRole);
    },
    productIdList: {
      handler(newValue, oldValue) {
        if (newValue.length < oldValue.length) {
          oldValue.forEach((item, index) => {
            if (newValue.indexOf(item) == -1 && this.$store.state.forcast_data.length != 0) {
              let oldLikeData = this.$store.state.forcast_data;
              oldLikeData.forEach((data) => {
                data.splice(index, 1);
              });
              this.$store.commit('$_setForcast_data', oldLikeData);
            }
          });
        }
      },
    },
    nameList: {
      handler(newValue, oldValue) {
        if (newValue.length < oldValue.length) {
          this.$store.state.likeProductList.forEach((items, indexs) => {
            let deleteRole = true;
            newValue.forEach((item) => {
              if (item.program == items.program && item.product == items.product) deleteRole = false;
            });
            if (deleteRole) this.$store.state.likeProductList.splice(indexs, 1);
          });
        }
      },
    },
    value: {
      handler(newval) {
        this.$store.commit('$_changeLine_num', newval);
      },
    },
  },
  created() {
    this.req_category();
    // !下拉数据逻辑判断
    this.likevalue = this.likeoptions[0].label;
    this.showLine = false;
    if (this.$store.state.lang === 'en') {
      this.likeoptions = this.likeoptions_en;
    }
  },

  methods: {
    // !根据下拉框的值进行显示
    likeChange(e) {
      console.log(e);
      let str = e;
      if (str == 'Not displayed' || str == '不显示') {
        console.log('无');
        this.showLine = false;
      }
      if (str == 'ALL SPF') {
        this.$EventBus.$emit('DoSeachernum', 1);
        console.log('1');
        this.showLine = true;
      }
      if (str === 'SPF≥50') {
        this.$EventBus.$emit('DoSeachernum', 2);
        this.showLine = true;
        console.log('2');
      }
      if (str === 'SPF<50') {
        this.$EventBus.$emit('DoSeachernum', 3);
        this.showLine = true;
        console.log('3');
      }
      if (str == '物理防晒' || str == 'Chemical') {
        this.$EventBus.$emit('DoSeachernum', 4);
        this.showLine = true;
        console.log('4');
      }
      if (str == '化学防晒' || str == 'Physical') {
        this.$EventBus.$emit('DoSeachernum', 5);
        this.showLine = true;
        console.log('5');
      }
      if (str == '物化结合' || str == 'Hybird') {
        this.$EventBus.$emit('DoSeachernum', 0);
        this.showLine = true;
        console.log('6');
      }
    },
    // 帮助中心子组件传给本组件
    unloding1(e) {
      console.log('触发父元素');
      this.$on(e, (this.helpDialogRole = false));
    },

    changhelpDialogRole() {
      console.log('触发');
      this.helpDialogRole = true;
    },
    // 控制刻度显示隐藏
    // changeK() {
    //   this.value=!this.value
    // },
    // NOTE loading 关闭按钮
    loadingClose() {
      clearInterval(this.loadingTimer1);
      this.forecastloading = false;
      this.loadingProgress1 = 0;
      this.loadingTimer1 = null;
      this.analyzeStatus1 = true;
    },
    // NOTE 品类选择框方法
    async classificationSelect() {
      this.contrast = [];
      let str = this.category == 'Sun care' ? '防晒霜' : this.category;
      let historyRes = await HttpClient.post('program/history_list', {
        user_id: this.$store.state.userid,
        category: str,
      });
      this.options = historyRes.result;
      let panelNameRes = await HttpClient.post('attribute/panel_info', {
        language: this.$store.state.lang,
      });
      this.$store.commit('$_changePanelNameList', panelNameRes.panel_attributes_item);
      this.$store.commit('$_changePanelNameList_en', panelNameRes.panel_attributes_item_en);
      let readerRes = await HttpClient.post('ctmproduct/historyproductinfo_predict_bar', {
        productlist: this.productIdList, //产品
        language: this.$store.state.lang,
      });

      // 处理jar数据
      let Jar = ['Concealer', 'Cooling', 'Thickness/consistency', 'Film foraming speed/set up speed'];
      this.indices = [];
      let index = []; // 查找jar的下标
      for (let i = 0; i < Jar.length; i++) {
        index.push(readerRes.predict_list_en.indexOf(Jar[i]));
      }
      this.indices = index.slice().sort((a, b) => b - a);
      // 删除jar数据
      this.indices.forEach((item) => {
        readerRes.predict_list_en.splice(item, 1);
      });
      this.indices.forEach((item) => {
        readerRes.predict_list.splice(item, 1);
      });
      console.log(readerRes.predict_list_en);
      this.$store.commit('$_changeLikeNameList_en', readerRes.predict_list_en);
      this.$store.commit('$_changeLikeNameList', readerRes.predict_list);
      if (this.disabled != '') this.disabled = false;
      else return;
    },
    handleSelectionExceeded(item) {
      if (item == 0) {
        this.$message.warning(this.$t(`message.History.limit`));
      } else {
        this.$message.warning(this.$t(`message.History.limits`));
      }
    },
    // NOTE 产品选择框方法
    async productSelect() {
      this.nameList = [];
      this.productIdList = [];
      this.contrast.forEach((item) => {
        this.productIdList.push(this.options[item[0]].children[item[1] - 1].productid);
        let name = {
          program: this.options[item[0]].label,
          product: this.options[item[0]].children[item[1] - 1].label,
        };
        this.nameList.push(name);
      });
      // FIXME 可能需要再次限制
      let res = await HttpClient.post('ctmproduct/historyproductinfo_panel_bar', {
        productlist: this.productIdList,
      });
      // this.$store.commit('$_changePanelNameList', res.panel_list);
      if (this.productIdList.length == 0) {
        this.$store.commit('$_changePanel_data', []);
        this.$store.commit('$_setForcast_data', []);
      } else this.$store.commit('$_changePanel_data', res.panel_data);
      this.$store.commit('$_changeProductNameList', this.nameList);
    },
    // NOTE 重置选择
    resetChoose() {
      this.contrast = [];
      this.productIdList = [];
      // this.$store.commit('$_changeLikeNameList', []);
      this.$store.commit('$_setForcast_data', []);
      this.$store.commit('$_changePanel_data', []);
      this.$store.commit('$_changeProductNameList', []);
      this.$store.commit('$_setForcast_data', []);
      this.$store.commit('$_changeLikeProductList', []);
      this.$store.commit(' $_changeLikeNameList', []);
      this.$store.commit(' $_changePanelNameList', []);
    },
    // NOTE 导出方法
    async historyDerive() {
      let type = this.$store.state.componentName;
      let postPath = '';
      if (type == 'panel') postPath = 'program/panelDerive';
      else if (type == 'like') postPath = 'program/predicitonDerive';
      let response = await HttpClient.post(
        postPath,
        { currentProduct: this.productIdList, language: this.$store.state.lang },
        { responseType: 'arraybuffer' }
      );
      let name = getFilename(response);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      let downloadElement = document.createElement('a');
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = `${name}`;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
      // console.log('this.$store.state.componentName :>> ', this.$store.state.componentName);
    },
    // NOTE 请求产品品类
    async req_category() {
      let res = await HttpClient.post('subuser/getUesrProduct', {
        _id: this.$store.state.userid,
      });
      this.category_arrs = res.classification;
      if (this.$store.state.lang == 'cn') {
        console.log('this.category_arrs CN:>> ', this.category_arrs);
        this.category_arrs.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case 'Sun care':
              this.$set(this.category_arrs, index, '防晒霜');
              break;
            case 'Toner':
              this.$set(this.category_arrs, index, ' 爽肤水');
              break;
            case ' Lotion':
              this.$set(this.category_arrs, index, '乳液');
              break;
            case 'Hand lotion':
              this.$set(this.category_arrs, index, '护手霜');
              break;
            default:
              break;
          }
        });
      } else {
        console.log('this.category_arrs EN :>> ', this.category_arrs);
        this.category_arrs.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case '防晒霜':
              // item = 'Sun care';
              this.$set(this.category_arrs, index, 'Sun care');
              break;
            case '爽肤水':
              item = 'Toner';
              this.$set(this.category_arrs, index, 'Toner');
              break;
            case '乳液':
              item = 'Lotion';
              this.$set(this.category_arrs, index, 'Lotion');
              break;
            case '护手霜':
              item = 'Hand lotion';
              this.$set(this.category_arrs, index, 'Hand lotion');
              break;
            default:
              break;
          }
        });
      }
    },
    // NOTE 切换组件
    changeComponent(type) {
      if (type == 'panel') {
        this.showValue = 0;
        this.change_BGcolor = 0;
        this.$store.commit('$_changeComponentName', type);
      } else {
        this.showValue = 1;
        this.change_BGcolor = 1;
        this.$store.commit('$_changeComponentName', type);
      }
    },
    // NOTE 分析数据
    async req_alg() {
      console.log('this.contrast :>> ', this.contrast);
      if (this.contrast.length == 0) {
        this.$message.warning(this.$t(`message.History.Comparison`));
        return;
      }
      this.loading = true;
      this.analyzeStatus = false;
      this.loadingTimer = setInterval(() => {
        this.loadingProgress += 100 / 60;
        console.log('this.loadingProgress1 :>> ', this.loadingProgress);
        if (this.loadingProgress >= 100) {
          if (!this.analyzeStatus) this.$message.warning('分析超时请重新尝试');
          clearInterval(this.loadingTimer);
          this.loadingProgress = 0;
          this.loading = false;
          this.loadingTimer = null;
        }
      }, 1000);
      let algres = await HttpClient.post('task/alg_exe2', {
        account_id: this.$store.state.userid,
        productlist: this.productIdList,
      });
      if (algres.message == 'success') {
        let readerRes = await HttpClient.post('ctmproduct/historyproductinfo_predict_bar', {
          productlist: this.productIdList, //产品
        });
        // this.$store.commit('$_changeLikeNameList', readerRes.predict_list);
        this.indices.forEach((item) => {
          readerRes.predict_data.splice(item, 1);
        });

        this.$store.commit('$_setForcast_data', readerRes.predict_data);
        this.$store.commit('$_changeLikeProductList', this.nameList);
        this.loadingProgress = 100;
        this.analyzeStatus = true;
      }
    },
    // ! 跳转判断
    keep() {
      console.log('跳');
      if (this.radarRole == false) {
        if (this.contrast.length > 8) {
          // 出现弹窗
          this.hint = true;
        } else if (this.contrast.length <= 8) {
          this.radarRole = !this.radarRole;
        }
      } else if (this.radarRole == true) {
        this.radarRole = !this.radarRole;
      }
    },
    true_keep() {
      this.hint = false;
      this.radarRole = !this.radarRole;
    },
  },
  mounted() {
    this.$EventBus.$on('RadarMap', () => {
      this.contrast = this.contrast.slice(0, 8);
      this.productIdList = this.productIdList.slice(0, 8);
      console.log('触发了处理数据');
    });
    if (this.$store.state.lang === 'en') {
      if (this.likevalue == '不显示') {
        this.likevalue = 'Not displayed';
      }
    }
  },
  components: { H },
};
</script>
<style lang="less" scoped>
@import url('../../style/History/DoSeach.less');
</style>
